::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#fff, $primary, #fff);
}

::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
