.section__portfolio {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  .btn {
    margin:5px;
  }
  .card {
    .card-image img {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    .card-reveal {
      .btn-small {
        cursor: default;
      }
    }
  }
}