.section__contact {
  #wrapper {
    min-height: 55vw;
    width: 100%;
    position: relative;
    overflow: hidden;
    @media #{$small-and-down} {
      min-height: 100vw;
    }
  }

  .layer {
    position: absolute;
    width: 100vw;
    min-height: 55vw;
    overflow: hidden;
    @media #{$small-and-down} {
      min-height: 100vw;
    }
    .content-wrap {
      position: absolute;
      width: 100vw;
      min-height: 55vw;
      @media #{$small-and-down} {
        min-height: 100vw;
      }
    }
    .content-body {
      width: 35%;
      position: absolute;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
      @media #{$small-and-down} {
        width: 100%;
      }
    }
    img {
      position: absolute;
      width: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
      @media #{$small-and-down} {
        top: 20%;
      }
    }
    h1 {
      font-size: 2em;
    }
  }

  .bottom {
    z-index: 1;
    .content-body {
      right: 5%;
      @media #{$small-and-down} {
        right: 0;
      }
    }
  }

  .top {
    z-index: 2;
    width: 50vw;
    .content-body {
      left: 5%;
      @media #{$small-and-down} {
        left: 0;
      }
    }
  }

  .handle {
    position: absolute;
    height: 100%;
    display: block;
    width: 5px;
    top: 0;
    left: 50%;
    z-index: 3;
  }

  .skewed {
    .handle {
      top: 50%;
      transform: rotate(30deg) translateY(-50%);
      height: 200%;
      transform-origin: top;
    }
    .top {
      transform: skew(-30deg);
      margin-left: -1000px;
      width: calc(50vw + 1000px);
      .content-wrap {
        transform: skew(30deg);
        margin-left: 1000px;
      }
    }
  }
}
