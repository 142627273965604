.section__gallery {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  .card-image {
    width:100%;
    height:250px;
    background-size: cover;
    background-position: center;
  }

}