
.modal__overlay {
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 998;
  .modal {
    z-index: 1003;
    width: 100%;
    opacity: 0;
    top: 4%;
    transform: scaleX(0.8) scaleY(0.8);
    transition: all 0.5s;
    background-color:transparent;
    box-shadow:none;
    overflow: hidden;
    &.open {
      transition: all 0.5s;
      opacity: 1;
      top: 10%;
      transform: scaleX(1) scaleY(1);
    }
  }
  .modal-footer {
    background:transparent;
    i {
      color:#fff;
      font-size:4em;
      cursor: pointer;
    }
  }
}