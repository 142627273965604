.portfolio-item {
  .tags__container {
    height: 60px;
    overflow: auto;
  }
  .sticky-action {
    background-size: cover;
  }
  .card-actions {
    a {
      display: flex;
    }
  }
}
